import { builder } from '@builder.io/react';
import { BUILDER_API_KEY } from '../shared/env';

builder.init(BUILDER_API_KEY);

export const getAllCategories = async (
  includeUnpublished = false,
  includeDraft = false
) => {
  const draft = includeDraft ?? 'draft';
  const categories = await builder.getAll('category', {
    options: { noTargeting: true, includeUnpublished },
    query: {
      published: {
        $ni: ['archived', draft],
      },
    },
  });
  return categories;
};

const getCategoryPage = async (
  params: { url?: string; modelId?: string },
  includeUnpublished = false
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryOption: any = {
    data: {},
    options: {
      includeUnpublished,
      noTargeting: false,
    },
  };
  if (params.url) {
    queryOption.url = '/category/' + params.url;
  }
  if (params.modelId) {
    queryOption.query.modelId = params.modelId;
  }

  const category =
    (await builder.get('category', queryOption).promise()) || null;
  return category;
};

export const getCategoryPageFromUrl = async (url = '', includeUnpublished = false) => {
  return getCategoryPage({ url }, includeUnpublished);
};

export const getCategoryFromId = async (
  modelId: string,
  includeUnpublished = false
) => {
  return getCategoryPage({ modelId }, includeUnpublished);
};
export const getMenuItems = async () => {
  const categories = await builder.getAll('category', {
    options: {
      noTargeting: true,
      includeUnpublished: true,
      sort: { createdDate: 1 },
    },
  });
  const menuItems = categories.map((category: any) => {
    const menuItem = {
      title: category?.data?.title,
      menuUrl: category?.data?.url,
    };
    return menuItem;
  });
  return menuItems;
};
