/* eslint-disable @typescript-eslint/no-explicit-any */
import { Builder, BuilderComponent } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { registerComponents } from '../../../builder';
import BlockNav from '../../../components/block-nav/block-nav';
import DefaultLayout from '../../../components/default-layout/default-layout';
import { getAllPage, getPageFromUrl } from '../../../helpers/PageHelper';
import { PageColorCtx } from '../../../helpers/shareContext';
import NotFoundPage from '../../../pages/not-found';
import styles from './page.module.scss';
registerComponents();

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
  const slug = params['slug'];
  const page = await getPageFromUrl(slug, Builder.isPreviewing);
  if (!page) {
    return <NotFoundPage />;
  }

  return {
    props: { page },
    revalidate: 5,
  };
}

export async function getStaticPaths() {
  const pages = await getAllPage();
  const paths = pages
    .filter((page: any) => page.data.url)
    .map((page: any) => `${page.data.url}`);
  return {
    paths,
    fallback: true,
  };
}

export default function Page(
  props: InferGetStaticPropsType<typeof getStaticProps> & {
    builderContent: BuilderContent;
  }
) {
  const { page } = props;
  const [pageColorCtx, setPageColorCtx] = useState<string>('');

  const router = useRouter();
  useEffect(() => {
    setPageColorCtx(page?.data?.color);
  }, [page]);

  if (router.isFallback) {
    return <h1>Loading...</h1>;
  }

  const getPageContent = () => {
    if (Builder.isEditing || Builder.isPreviewing) {
      return props.builderContent;
    }
    return page;
  };

  const renderDataSchema = () => {
    if (page?.data?.url !== '/page/apple-pay') {
      return null;
    }
    const applePaySChema = {
      '@context': 'https://schema.org/',
      '@type': 'MobileApplication',
      applicationCategory: 'https://www.moneybyafterpay.com/apple-pay',
      supportingData: {
        '@type': 'DataFeed',
        dataFeedElement: [
          {
            '@type': 'FinancialService',
            name: 'Money by Afterpay',
            sameAs: 'https://www.moneybyafterpay.com',
          },
          {
            '@type': 'BankAccount',
            name: 'Money by Afterpay',
            sameAs: 'https://www.moneybyafterpay.com',
          },
          {
            '@type': 'FinancialProduct',
            name: 'Money by Afterpay',
            sameAs: 'https://www.moneybyafterpay.com',
          },
        ],
      },
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(applePaySChema).toString(),
        }}
      />
    );
  };
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>{props?.page?.data?.title}</title>
        <meta name="description" content={props?.page?.data?.description} />
        {renderDataSchema()}
      </Head>
      <DefaultLayout>
        <BlockNav />
        <PageColorCtx.Provider
          value={{ pageColorCtx: pageColorCtx, setPageColorCtx }}
        >
          <div className={styles.pageWrap}>
            <BuilderComponent model="page" content={getPageContent()} />
          </div>
        </PageColorCtx.Provider>
      </DefaultLayout>
    </>
  );
}
