import { builder } from '@builder.io/react';
import { BUILDER_API_KEY } from '../shared/env';

builder.init(BUILDER_API_KEY);

export const getAllArticles = async (
  includeUnpublished = false,
  includeDraft = false
) => {
  const draft = includeDraft ?? 'draft';
  const articles = await builder.getAll('article', {
    options: { noTargeting: true, includeUnpublished },
    query: {
      published: {
        $ni: ['archived', draft],
      },
    },
  });
  return articles;
};

const getArticlePage = async (
  params: { url?: string; modelId?: string },
  includeUnpublished = false
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryOption: any = {
    data: {},
    query:{},
    options: {
      includeUnpublished,
      noTargeting: false,
    },
  };
  if (params.url) {
    queryOption.url = '/article/' + params.url;
  }
  if (params.modelId) {
    queryOption.query.modelId = params.modelId;
  }

  const article = (await builder.get('article', queryOption).promise()) || null;
  return article;
};

export const getArticlePageFromUrl = async (
  url = '',
  includeUnpublished = false
) => {
  return getArticlePage({ url }, includeUnpublished);
};

export const getArticleFromId = async (
  modelId: string,
  includeUnpublished = false
) => {
  return getArticlePage({ modelId }, includeUnpublished);
};
