import { BuilderContent } from '@builder.io/sdk';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { getArticlePageFromUrl } from '../helpers/ArticleHelper';
import { getCategoryPageFromUrl } from '../helpers/CategoryHelper';
import { getPageFromUrl } from '../helpers/PageHelper';
import {
  default as Article,
  getStaticPaths as getStaticPathsArticle,
} from '../modules/pages/article';
import {
  default as Category,
  getStaticPaths as getStaticPathsCategory,
} from '../modules/pages/category';
import {
  default as Page,
  getStaticPaths as getStaticPathsPage,
} from '../modules/pages/page';
import NotFoundPage from './not-found';

export async function getStaticPaths() {
  const pagesCategory = (await getStaticPathsCategory()).paths;
  const pagesPage = (await getStaticPathsPage()).paths;
  const pageArticle = await (await getStaticPathsArticle()).paths;
  const paths = [
    ...pagesCategory.map((slug) => slug.replace('/category', '')),
    ...pagesPage.map((slug) => slug.replace('/page', '')),
    ...pageArticle.map((slug) => slug.replace('/article', '')),
  ];
  return {
    paths,
    fallback: true,
  };
}

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
  const slug = params.slug.join('/');
  const page = await getPageFromUrl(slug, true);
  if (page) {
    return {
      props: { page, model: 'page' },
      revalidate: 5,
    };
  }

  const category = await getCategoryPageFromUrl(slug);
  if (category) {
    return {
      props: { page: category, model: 'category' },
      revalidate: 5,
    };
  }

  const article = await getArticlePageFromUrl(slug, true);
  if (article) {
    return {
      props: { page: article, model: 'article' },
      revalidate: 5,
    };
  }
  return { props: { model: '404' } };
}

export default function PageRoot(
  props: InferGetStaticPropsType<typeof getStaticProps> & {
    builderContent: BuilderContent;
  } & {
    model: string;
  }
) {

  const model = props['model'];
  if (model === '404') {
    return <NotFoundPage />;
  }
  if (model === 'page') {
    return <Page {...props} />;
  }
  if (model === 'category') {
    return <Category {...props} />;
  }
  if (model === 'article') {
    return <Article {...props} />;
  }
  return null;
}
