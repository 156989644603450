/* eslint-disable @typescript-eslint/no-explicit-any */
import { Builder, BuilderComponent } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { registerComponents } from '../../../builder';
import DefaultLayout from '../../../components/default-layout/default-layout';
import {
  getAllCategories,
  getCategoryPageFromUrl,
} from '../../../helpers/CategoryHelper';
import { PageColorCtx } from '../../../helpers/shareContext';
import NotFoundPage from '../../../pages/not-found';
import styles from './category.module.scss';
registerComponents();

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
  const slug = params['category-slug'];
  const page = await getCategoryPageFromUrl(slug, Builder.isPreviewing);
  if (!page) {
    return <NotFoundPage />;
  }
  return {
    props: { page },
    revalidate: 5,
  };
}

export async function getStaticPaths() {
  const pages = await getAllCategories(true);
  const paths = pages
    .filter((page: any) => page.data.url)
    .map((page: any) => `${page.data.url}`);

  return {
    paths,
    fallback: true,
  };
}

export default function Page(
  props: InferGetStaticPropsType<typeof getStaticProps> & {
    builderContent: BuilderContent;
  }
) {
  const { page } = props;
  const [pageColorCtx, setPageColorCtx] = useState<string>('');

  const router = useRouter();
  useEffect(() => {
    setPageColorCtx(page?.data?.color);
  }, [page]);

  if (router.isFallback) {
    return <h1>Loading...</h1>;
  }

  const getPageContent = () => {
    if (Builder.isEditing || Builder.isPreviewing) {
      return props.builderContent;
    }
    return page;
  };

  const renderDateSchemaHomePage = () => {
    if (
      page?.data?.url === '' ||
      page?.data?.url === '/' ||
      page?.data?.url === '/home' ||
      page?.data?.url === '/category/home'
    ) {
      const dataSchema = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Money by Afterpay',
        url: 'https://www.moneybyafterpay.com/',
        logo: 'https://www.moneybyafterpay.com/images/logo.png',
        sameAs: [
          'https://www.facebook.com/moneybyafterpay/',
          'https://www.instagram.com/moneybyafterpay/',
        ],
      };
      const breadCrumbs = {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Money by Afterpay',
            item: 'https://www.moneybyafterpay.com/',
          },
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Money is Closing',
            item: 'https://www.moneybyafterpay.com/money-is-closing',
          },
          // {
          //   '@type': 'ListItem',
          //   position: 2,
          //   name: 'MoneyFlow',
          //   item: 'https://www.moneybyafterpay.com/moneyflow',
          // },
          // {
          //   '@type': 'ListItem',
          //   position: 3,
          //   name: 'Superpowers',
          //   item: 'https://www.moneybyafterpay.com/super-powers',
          // },
          // {
          //   '@type': 'ListItem',
          //   position: 4,
          //   name: 'FutureYou',
          //   item: 'https://www.moneybyafterpay.com/future-you',
          // },
          {
            '@type': 'ListItem',
            position: 3,
            name: 'FAQs',
            item: 'https://help.moneybyafterpay.com/',
          },
        ],
      };
      const financialProduct = {
        '@context': 'https://schema.org',
        '@type': 'FinancialProduct',
        name: 'Saving Account',
        description:
          'Start with one savings account (aka savings goal) and create up to 15.Interest rate on savings goal is 0.75%pa. subject to a combined total balance of $50,000 across all savings goals. ',
        amount: {
          '@type': 'MonetaryAmount',
          currency: 'AUD',
          minValue: '0',
          maxValue: '1000000',
        },
        offers: {
          '@type': 'Offer',
          offeredBy: 'https://www.moneybyafterpay.com',
        },
      };
      return (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(dataSchema) }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(breadCrumbs) }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(financialProduct),
            }}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>{props?.page?.data?.title}</title>
        <meta name="description" content={props?.page?.data?.description} />
        {renderDateSchemaHomePage()}
      </Head>
      <DefaultLayout>
        <PageColorCtx.Provider value={{ pageColorCtx, setPageColorCtx }}>
          <div className={styles.categoryPageWrap}>
            <BuilderComponent model="category" content={getPageContent()} />
          </div>
        </PageColorCtx.Provider>
      </DefaultLayout>
    </>
  );
}
