/* eslint-disable @typescript-eslint/no-explicit-any */
import { Builder, BuilderComponent } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { registerComponents } from '../../../builder';
import BackgroundFullWidth from '../../../components/background-full-width/background-full-width';
import BlockNav from '../../../components/block-nav/block-nav';
import DefaultLayout from '../../../components/default-layout/default-layout';
import {
  getAllArticles,
  getArticleFromId,
  getArticlePageFromUrl,
} from '../../../helpers/ArticleHelper';
import { PageArticleCtx } from '../../../helpers/shareContext';
import NotFoundPage from '../../../pages/not-found';
import { ArticleCtxProps } from '../../../types/Article';
import styles from './article.module.scss';
registerComponents();

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
  const slug = params['article-slug'];
  const page = await getArticlePageFromUrl(slug, Builder.isPreviewing);
  if (!page) {
    return <NotFoundPage />;
  }
  return {
    props: { page },
    revalidate: 5,
  };
}

export async function getStaticPaths() {
  const pages = await getAllArticles(true);
  const paths = pages
    .filter((page: any) => page.data.url)
    .map((page: any) => `${page.data.url}`);
  return {
    paths,
    fallback: true,
  };
}

export default function Page(
  props: InferGetStaticPropsType<typeof getStaticProps> & {
    builderContent: BuilderContent;
  }
) {
  const [page, setPage] = useState<BuilderContent>();
  const [articleCtxData, setPArticleCtxData] = useState<ArticleCtxProps>(
    {} as ArticleCtxProps
  );

  const router = useRouter();
  const articleId = router.query['builder.overrides.article'] ?? '';
  const getArticleById = async () => {
    const article = await getArticleFromId(
      articleId as string,
      Builder.isPreviewing
    );
    setPage(article);
  };

  useEffect(() => {
    if ((Builder.isPreviewing || Builder.isEditing) && articleId) {
      getArticleById();
    } else {
      if (props.page) {
        setPage(props.page);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, articleId, router]);

  useEffect(() => {
    const articleData = {
      pageColor: page?.data?.color,
      createdDate: (page as any)?.createdDate,
      publishedDate: page?.data?.publishedDate,
      tags: page?.data?.tags,
      timeToRead: page?.data?.timeToRead,
      title: page?.data?.title,
      description: page?.data?.description,
    };
    setPArticleCtxData(articleData);
  }, [page]);

  if (router.isFallback) {
    return <h1>Loading...</h1>;
  }

  const getPageContent = () => {
    return page;
  };
  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const renderDataSchema = () => {
    if (typeof window !== 'object') {
      return null;
    }
    const headLine = document?.querySelector('h1');
    const dataSchema = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `https://www.moneybyafterpay.com${page?.data?.url?.replace(
          '/article',
          ''
        )}`,
      },
      headline: `${headLine?.textContent ?? ''}`,
      description: `${page?.data?.description ?? ''}`,
      image: `${page?.data?.leadImage ?? ''}`,
      author: {
        '@type': 'Organization',
        name: 'Money by Afterpay',
        url: 'https://www.moneybyafterpay.com/',
      },
      publisher: {
        '@type': 'Organization',
        name: 'Money by Afterpay',
        logo: {
          '@type': 'ImageObject',
          url: 'https://www.moneybyafterpay.com/images/logo.png',
        },
      },
      datePublished: `${formatDate(page?.data?.publishedDate) ?? ''}`,
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(dataSchema) }}
      />
    );
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>{props?.page?.data?.title}</title>
        <meta name="description" content={props?.page?.data?.description} />
        {renderDataSchema()}
      </Head>
      <div className={styles.articlePageWrap}>
        <DefaultLayout>
          <BlockNav />
          <PageArticleCtx.Provider
            value={{ articleCtxData, setPArticleCtxData }}
          >
            <BuilderComponent model="article" content={getPageContent()} />
          </PageArticleCtx.Provider>
          <BackgroundFullWidth
            backgroundColor={articleCtxData.pageColor}
            zIndex={-2}
          />
        </DefaultLayout>
      </div>
    </>
  );
}
